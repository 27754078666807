import { on, off } from "../../components/utils/MDBEventHandlers";

const defaultOptions = {
  src: "",
  placeholder: "https://place-hold.it/600x400?text=Loading",
  error: "https://place-hold.it/600x400?text=Error",
  delay: 500,
  offset: 0,
  animation: false,
  parent: null,
  parentEl: null,
  childElements: null,
};

export default {
  mounted(el, binding) {
    if (!el) {
      return;
    }

    const { arg } = binding;

    const options = {
      ...defaultOptions,
      ...binding.value,
    };
    if (options.parent) {
      options.parentEl = document.querySelector(options.parent);
    }

    if (arg && arg === "container") {
      options.childElements = el.querySelectorAll("img, video");

      options.childElements.forEach((child) => {
        on(child, "error", () => errorHandler(child));
      });
    }

    setPlaceholder();

    function setPlaceholder() {
      if (arg && arg === "container") {
        options.childElements.forEach((child) => {
          const { lazyPlaceholder } = child.dataset;

          if (child.nodeName === "IMG") {
            child.setAttribute("src", lazyPlaceholder || options.placeholder);
          } else if (child.nodeName === "VIDEO") {
            child.setAttribute(
              "poster",
              lazyPlaceholder || options.placeholder
            );
          }
        });

        return;
      }
      if (el.nodeName === "IMG") {
        el.setAttribute("src", options.placeholder);
      } else if (el.nodeName === "VIDEO") {
        el.setAttribute("poster", options.placeholder);
      }
    }

    function inViewport() {
      const offsetValues = el.getBoundingClientRect();

      if (options.parentEl) {
        const parentRect = options.parentEl.getBoundingClientRect();
        return (
          parentRect.y > 0 &&
          parentRect.y < window.innerHeight &&
          offsetValues.y >= parentRect.y &&
          offsetValues.y <= parentRect.y + parentRect.height &&
          offsetValues.y <= window.innerHeight
        );
      }

      return (
        offsetValues.top + options.offset <= window.innerHeight &&
        offsetValues.bottom >= 0
      );
    }

    function setSrc(el, src) {
      el.setAttribute("src", src);
    }

    el.scrollHandler = () => {
      if (inViewport()) {
        el.timeout = setTimeout(() => {
          if (arg && arg === "container") {
            options.childElements.forEach((child) => {
              const { lazySrc, lazyAnimation } = child.dataset;

              if (options.animation || lazyAnimation) {
                child.classList.add("animation");
                child.classList.add(lazyAnimation || options.animation);
              }

              setSrc(child, lazySrc || options.src);
            });
          } else {
            if (options.animation) {
              el.classList.add("animation");
              el.classList.add(options.animation);
            }

            setSrc(el, options.src);
          }
        }, options.delay);

        if (options.parentEl) {
          off(options.parentEl, "scroll", el.scrollHandler);
        }

        off(window, "scroll", el.scrollHandler);
      }
    };

    function errorHandler(el) {
      const { lazyError } = el.dataset;
      el.setAttribute("src", lazyError || options.error);
    }

    el.scrollHandler();

    if (options.parent) {
      on(options.parentEl, "scroll", el.scrollHandler);
    } else {
      on(window, "scroll", el.scrollHandler);
    }

    on(el, "error", () => errorHandler(el));
  },
  unmounted(el) {
    off(window, "scroll", el.scrollHandler);
  },
};
