const defaultOptions = {
  animationSticky: "",
  animationUnsticky: "",
  boundary: false,
  direction: "down",
  media: 0,
  offset: 0,
  delay: 0,
  position: "top",
};

export default {
  mounted(el, binding) {
    if (!el) {
      return;
    }

    const options = {
      ...defaultOptions,
      ...binding.value,
    };
    let windowScrollTop = 0;
    let scrollDirection = null;
    let hiddenElement = null;

    el.isSticked = false;
    const elStyle = el.style;

    el.classList.add("sticky");

    // get initial position of the element in the view
    if (!("IntersectionObserver" in window)) {
      // setTimeout is obligatory for proper element position check
      // when some of the elements are not in their position right after the page loads
      setTimeout(() => {
        const originalScrollTop =
          window.pageYOffset || document.documentElement.scrollTop;

        el.originalScrollTop =
          el.getBoundingClientRect().top + originalScrollTop;
      }, 100);
    } else {
      // intersectionObserver will detect element position if e.g.
      // some of the page content is lazy loaded
      // and element's original position may be different than the position on page load
      const intersectionOptions = {
        rootMargin: "0px",
        threshold: 1,
      };

      const callback = (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const originalScrollTop =
              window.pageYOffset || document.documentElement.scrollTop;

            el.originalScrollTop =
              el.getBoundingClientRect().top + originalScrollTop;

            observer.disconnect();
          }
        });
      };

      let observer = new IntersectionObserver(callback, intersectionOptions);

      observer.observe(el);
    }

    const setStickyStyle = () => {
      const { height, left, width } = el.getBoundingClientRect();
      elStyle.position = "fixed";
      elStyle.top =
        options.position === "top" && `${0 + parseInt(options.offset)}px`;
      elStyle.bottom =
        options.position === "bottom" && `${0 + parseInt(options.offset)}px`;
      elStyle.height = `${height}px`;
      elStyle.width = `${width}px`;
      elStyle.left = `${left}px`;
      elStyle.marginLeft = 0;
      elStyle.zIndex = "100";

      return;
    };

    const resetStickyStyle = () => {
      elStyle.position = null;
      elStyle.top = null;
      elStyle.bottom = null;
      elStyle.height = null;
      elStyle.width = null;
      elStyle.left = null;
      elStyle.zIndex = null;
      elStyle.marginLeft = null;

      return;
    };

    const toggleClass = (addClass, removeClass, target) => {
      if (addClass) {
        target.classList.add(addClass);
      }

      if (removeClass) {
        target.classList.remove(removeClass);
      }
    };

    const activateSticky = () => {
      if (el.isSticked) {
        return;
      }

      if (options.animationSticky !== "") {
        el.classList.add("animation");
        toggleClass(options.animationSticky, options.animationUnsticky, el);
      }

      setStickyStyle();
      hiddenElement.hidden = false;
      el.isSticked = true;
    };

    const deactivateSticky = () => {
      if (!el.isSticked) {
        return;
      }

      const { animationDuration } = getComputedStyle(el);

      const duration =
        options.animationUnsticky !== ""
          ? parseFloat(animationDuration) * 1000
          : 0;

      if (options.animationUnsticky !== "") {
        el.classList.add("animation");
        toggleClass(options.animationUnsticky, options.animationSticky, el);
      }

      setTimeout(() => {
        if (el.classList.contains(options.animationSticky)) {
          return;
        }

        toggleClass("", options.animationUnsticky, el);
        resetStickyStyle();
        removeHiddenElement();
        el.isSticked = false;
      }, duration);
    };

    const updateScrollDirection = (scrollTop) => {
      if (scrollTop > windowScrollTop) {
        scrollDirection = "down";
      } else {
        scrollDirection = "up";
      }
    };

    const checkPushPoint = (scrollTop) => {
      const elHeight = el.getBoundingClientRect().height;
      if (options.position === "top") {
        return (
          options.offset >=
          el.originalScrollTop - scrollTop + parseInt(options.delay)
        );
      }

      return (
        el.originalScrollTop + elHeight <
        window.innerHeight + scrollTop - parseInt(options.delay)
      );
    };

    const getOffset = (element) => {
      const rect = element.getBoundingClientRect();

      const offsetElement = {
        top: rect.top + document.body.scrollTop,
        left: rect.left + document.body.scrollLeft,
      };

      const bottom =
        offsetElement.left === 0 && offsetElement.top === 0
          ? 0
          : window.innerHeight - rect.bottom;

      return {
        ...offsetElement,
        bottom,
      };
    };

    const copyElement = (elementToCopy) => {
      const { height, width } = elementToCopy.getBoundingClientRect();
      const copiedItem = elementToCopy.cloneNode(false);
      copiedItem.hidden = true;

      const copiedItem_STYLE = copiedItem.style;

      copiedItem_STYLE.height = `${height}px`;
      copiedItem_STYLE.width = `${width}px`;
      copiedItem_STYLE.opacity = "0";

      elementToCopy.parentElement.insertBefore(copiedItem, elementToCopy);

      return copiedItem;
    };

    const createHiddenElement = () => {
      if (!hiddenElement) {
        hiddenElement = copyElement(el);
      }
    };

    const removeHiddenElement = () => {
      // prevent to throw error when hidden Element don't exist;
      if (!hiddenElement) {
        return;
      }

      hiddenElement.remove();
      hiddenElement = null;
    };

    const checkBoundaryPosition = () => {
      let { position, boundary, offset } = options;
      const { height } = el.getBoundingClientRect();
      const parentOffset = {
        height: el.parentElement.getBoundingClientRect().height,
        ...getOffset(el.parentElement),
      };

      offset = parseInt(offset);

      let stopPoint;
      const stopper = document.querySelector(boundary);

      if (stopper) {
        stopPoint = getOffset(stopper).top - height - offset;
      } else {
        stopPoint =
          parentOffset.height + parentOffset[position] - height - offset;
      }

      const isStickyTop = position === "top";
      const isStickyBottom = position === "bottom";
      const isStickyBoundary = boundary;
      const isBelowStopPoint = stopPoint < 0;
      const isBelowParentElementEnd = stopPoint > parentOffset.height - height;

      if (isStickyTop) {
        if (isBelowStopPoint && isStickyBoundary) {
          elStyle.top = `${offset + stopPoint}px`;
        } else {
          elStyle.top = `${offset + 0}px`;
        }
      }

      if (isStickyBottom) {
        if (isBelowStopPoint && isStickyBoundary) {
          elStyle.bottom = `${offset + stopPoint}px`;
        } else if (isBelowParentElementEnd && isStickyBoundary) {
          elStyle.bottom = `${offset + parentOffset.bottom}px`;
        } else {
          elStyle.bottom = `${offset + 0}px`;
        }
      }
    };

    const scrollHandler = () => {
      const doc = document.documentElement;
      const scrollTop = window.pageYOffset || doc.scrollTop;

      updateScrollDirection(scrollTop);

      const isCorrectScrollDirection = [scrollDirection, "both"].includes(
        options.direction
      );

      const isPushPointReached = checkPushPoint(scrollTop);

      const shouldBeSticky =
        isPushPointReached && !el.isSticked && isCorrectScrollDirection;
      const shouldNotBeSticky =
        (!isPushPointReached || !isCorrectScrollDirection) && el.isSticked;

      if (shouldBeSticky) {
        createHiddenElement();
        activateSticky();
        checkBoundaryPosition();
      }

      if (shouldNotBeSticky) {
        deactivateSticky();
      }

      if (el.isSticked) {
        checkBoundaryPosition();
      }

      windowScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    el.scrollInit = () => {
      // prevent action if browser resolution <= user acceptable resolution
      if (window.innerWidth <= options.media) {
        return;
      }

      if (!window.requestAnimationFrame) {
        return setTimeout(scrollHandler, 16);
      }

      window.requestAnimationFrame(scrollHandler);
    };

    window.addEventListener("scroll", el.scrollInit);
  },
  unmounted(el, binding) {
    if (binding.value === false) {
      return;
    }

    window.removeEventListener("scroll", el.scrollInit);
  },
};
