import Chart from "chart.js/auto";

import ChartDataLabels from "chartjs-plugin-datalabels";
import chartsDefaultOptions from "./chartsDefaultOptions";
import merge from "deepmerge";
import { ref } from "vue";

function generateCharts(canvasRef, type, data = {}, options = {}) {
  const _data = ref(data);
  const _chart = ref(null);
  const _options = ref(null);
  const _plugins = ref(null);

  setupCharts();

  function setupCharts() {
    _options.value = mergeOptions(options, type, chartsDefaultOptions);
  }

  function mergeOptions(options, type, defaultOptions) {
    if (!options) {
      options = {};
    }

    const mergeObjects = (target, source, options) => {
      const destination = target.slice();
      source.forEach((item, index) => {
        if (typeof destination[index] === "undefined") {
          destination[index] = options.cloneUnlessOtherwiseSpecified(
            item,
            options
          );
        } else if (options.isMergeableObject(item)) {
          destination[index] = merge(target[index], item, options);
        } else if (target.indexOf(item) === -1) {
          destination.push(item);
        }
      });
      return destination;
    };
    return merge(defaultOptions[type], options, {
      arrayMerge: mergeObjects,
    });
  }

  function addDataLabels() {
    _plugins.value = [ChartDataLabels];
  }

  function renderChart() {
    destroyChart();

    _chart.value = new Chart(canvasRef.getContext("2d"), {
      type,
      data: _data.value,
      options: _options.value,
      plugins: _plugins.value,
    });
  }

  function updateChart(data, options) {
    _options.value = mergeOptions(options, type, chartsDefaultOptions);
    _data.value = data;

    renderChart();
  }

  function destroyChart() {
    if (_chart.value) {
      _chart.value.destroy();
      _chart.value = null;
    }
  }

  return {
    setupCharts,
    renderChart,
    addDataLabels,
    destroyChart,
    updateChart,
  };
}

export default generateCharts;
