const defaultOptions = {
  container: "body",
  offset: 0,
  easing: "linear",
  duration: 500,
};

const motions = {
  motionLinear: (t) => {
    return t;
  },

  // Ease-In

  motionEaseInQuad: (t) => {
    return t * t;
  },

  motionEaseInCubic: (t) => {
    return t * t * t;
  },

  motionEaseInQuart: (t) => {
    return t * t * t * t;
  },

  motionEaseInQuint: (t) => {
    return t * t * t * t * t;
  },

  // Ease-In-Out

  motionEaseInOutQuad: (t) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  },

  motionEaseInOutCubic: (t) => {
    t /= 0.5;
    if (t < 1) return (t * t * t) / 2;
    t -= 2;
    return (t * t * t + 2) / 2;
  },

  motionEaseInOutQuart: (t) => {
    t /= 0.5;
    if (t < 1) return 0.5 * t * t * t * t;
    t -= 2;
    return -(t * t * t * t - 2) / 2;
  },

  motionEaseInOutQuint: (t) => {
    t /= 0.5;
    if (t < 1) return (t * t * t * t * t) / 2;
    t -= 2;
    return (t * t * t * t * t + 2) / 2;
  },

  // Ease-Out

  motionEaseOutQuad: (t) => {
    return -t * (t - 2);
  },

  motionEaseOutCubic: (t) => {
    t--;
    return t * t * t + 1;
  },

  motionEaseOutQuart: (t) => {
    t--;
    return -(t * t * t * t - 1);
  },

  motionEaseOutQuint: (t) => {
    t--;
    return t * t * t * t * t + 1;
  },
};

export default {
  mounted(el, binding) {
    const href = el.getAttribute("href") || binding.value;

    const options = {
      ...defaultOptions,
      ...binding.value,
    };

    const isWindow = () => {
      return options.container === "body";
    };

    const containerToScroll = isWindow()
      ? document.documentElement
      : document.querySelector(options.container);

    const easingFunction = () => {
      const easing = options.easing;
      const motionName = `motion${easing[0].toUpperCase()}${easing.slice(1)}`;
      return motions[motionName] ? motions[motionName] : motions.motionLinear;
    };

    const offsetFromEl = () => {
      const heightFromTop = containerToScroll.scrollTop;
      const el = document.querySelector(href);
      const elY = el.getBoundingClientRect().y;

      if (isWindow()) {
        return elY - options.offset + heightFromTop;
      }
      const containerY = containerToScroll.getBoundingClientRect().y;
      const offsetFromContainer = elY - containerY;

      return offsetFromContainer - options.offset + heightFromTop;
    };

    const inViewport = () => {
      if (isWindow()) {
        return true;
      }
      const rect = containerToScroll.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight)
      );
    };

    const scrollOnNextTick = (
      scrollingContainer,
      positionFrom,
      positionTo,
      scrollProgress,
      speed,
      step,
      easing
    ) => {
      const progressWrongValue = scrollProgress < 0;
      const scrollEnd = scrollProgress > 1;
      const speedWrongValue = speed <= 0;
      if (progressWrongValue || scrollEnd || speedWrongValue) {
        if (binding.value.callback) {
          binding.value.callback("mdbScrollEnd");
        } else {
          el.dispatchEvent(new CustomEvent("mdbScrollEnd"));
        }
        scrollingContainer.scrollTop = positionTo;
        return;
      }
      scrollingContainer.scrollTo({
        top:
          positionFrom - (positionFrom - positionTo) * easing(scrollProgress),
      });
      scrollProgress += speed * step;

      // After one tick ends next tick is able to run, otherwise scrolling aren't goint to be animated
      setTimeout(() => {
        scrollOnNextTick(
          scrollingContainer,
          positionFrom,
          positionTo,
          scrollProgress,
          speed,
          step,
          easing
        );
      });
    };

    el.handleClick = (e) => {
      e.preventDefault();

      const scrollingContainer = containerToScroll;
      const positionFrom = containerToScroll.scrollTop;
      const positionTo = offsetFromEl();
      const scrollProgress = 0;
      const speed = 1 / options.duration;
      // Thanks to this value time of scrolling is almost equal to value which user set
      const step = 4.25;
      el.easing = easingFunction();

      if (!inViewport()) {
        if (binding.value.callback) {
          binding.value.callback("mdbScrollStart");
        } else {
          el.dispatchEvent(new CustomEvent("mdbScrollStart"));
        }

        scrollOnNextTick(
          document.documentElement,
          document.documentElement.scrollTop,
          containerToScroll.offsetTop,
          scrollProgress,
          speed,
          step,
          el.easing
        );

        // Function which is waiting for ends scrolling to viewport
        setTimeout(() => {
          scrollOnNextTick(
            scrollingContainer,
            positionFrom,
            positionTo,
            scrollProgress,
            speed,
            step,
            el.easing
          );
        }, options.duration);
      } else {
        if (binding.value.callback) {
          binding.value.callback("mdbScrollStart");
        } else {
          el.dispatchEvent(new CustomEvent("mdbScrollStart"));
        }

        scrollOnNextTick(
          scrollingContainer,
          positionFrom,
          positionTo,
          scrollProgress,
          speed,
          step,
          el.easing
        );
      }
    };

    el.addEventListener("click", (e) => {
      el.handleClick(e);
    });
  },
  unmounted(el) {
    el.removeEventListener("click", () => {
      el.handleClick();
    });
  },
};
