export default {
  mounted(el, binding) {
    const { arg } = binding;

    if (arg === "window") {
      el.scrollableContainer = window;
      el.prevScrollPosition = window.scrollY;
    } else if (
      el.style.overflowY === "scroll" ||
      el.style.overflowX === "scroll"
    ) {
      el.scrollableContainer = el;
      el.prevScrollPosition = arg === "x" ? el.scrollLeft : el.scrollTop;
    }

    el.handleScroll = () => {
      const rect = el.getBoundingClientRect();

      let condition;
      let scrollPosition;

      if (el.scrollableContainer === el) {
        if (arg === "x") {
          condition = rect.width + el.scrollLeft + 10 >= el.scrollWidth;
          scrollPosition = el.scrollLeft;
        } else {
          condition = rect.height + el.scrollTop >= el.scrollHeight;
          scrollPosition = el.scrollTop;
        }
      } else if (arg === "window") {
        condition =
          window.scrollY + window.innerHeight >=
          document.documentElement.scrollHeight;
        scrollPosition = window.scrollY;
      }

      if (scrollPosition < el.prevScrollPosition) return;

      if (condition) {
        el.prevScrollPosition = scrollPosition;
        binding.value();
      }
    };

    el.scrollableContainer.addEventListener("scroll", el.handleScroll);
  },
  unmounted(el) {
    el.scrollableContainer.removeEventListener("scroll", el.handleScroll);
  },
};
