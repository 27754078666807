/* eslint-disable prettier/prettier */
const handlers = {
  tap: (e, data) => {
    if (e.touches.length === data.pointers) {
      data.origin = {
        x: e.touches[0].clientX,
        y: e.touches[0].clientY,
      };

      data.tapCount += 1;

      if (data.tapCount === 1) {
        data.timer = setTimeout(() => {
          data.tapCount = 0;
        }, data.interval);
      }

      if (data.tapCount === data.taps) {
        clearTimeout(data.timer);
        data.tapCount = 0;
        data.callback({
          origin: data.origin,
        });
      }
    }
  },
  swipe: (e, data) => {
    if (e.type === "touchstart") {
      data.startTouch = e;
    } else if (e.type === "touchmove") {
      e.preventDefault();
      if (!data.startTouch) {
        return;
      }
      data.touch = e;
      const {
        clientX,
        clientY
      } = data.startTouch.touches[0];
      const {
        clientX: x,
        clientY: y
      } = data.touch.touches[0];

      const moveValue = {
        x: x - clientX,
        y: y - clientY,
      };

      const displacement = {
        x: {
          direction: moveValue.x < 0 ? "left" : "right",
          value: Math.abs(moveValue.x),
        },
        y: {
          direction: moveValue.y < 0 ? "up" : "down",
          value: Math.abs(moveValue.y),
        },
      };

      if (
        data.direction === "all" &&
        (displacement.x.value > data.threshold ||
          displacement.y.value > data.threshold)
      ) {
        const direction =
          displacement.y.value > displacement.x.value ?
          displacement.y.direction :
          displacement.x.direction;
        data.callback(direction);

        data.startTouch = null;
        data.touch = null;
      } else {
        const axis =
          data.direction === "left" ||
          data.direction === "right" ||
          data.direction === "x" ?
          "x" :
          "y";

        if (
          displacement[axis].value > data.threshold &&
          (data.direction === axis ||
            displacement[axis].direction === data.direction)
        ) {
          data.callback(displacement[axis].direction);

          data.startTouch = null;
          data.touch = null;
        }
      }
    }
  },
  pinch(e, data) {
    if (e.touches.length < 2 && e.type !== "touchend") {
      return;
    }

    e.preventDefault();

    if (e.type === "touchstart") {
      const [touch1, touch2] = e.touches;
      data.startTouch = Math.sqrt(
        (touch2.clientX - touch1.clientX) ** 2 +
        (touch2.clientY - touch1.clientY) ** 2
      );
      data.origin = {
        x: touch1.clientX + (touch2.clientX - touch1.clientX) / 2,
        y: touch1.clientY + (touch2.clientY - touch1.clientY) / 2,
      };

      const ratio = data.touch / data.startTouch;

      data.callback({
        ratio,
        origin: data.origin,
      });
    } else if (e.type === "touchmove") {
      const [touch1, touch2] = e.touches;

      data.touch = Math.sqrt(
        (touch2.clientX - touch1.clientX) ** 2 +
        (touch2.clientY - touch1.clientY) ** 2
      );
    }
    const ratio = data.touch / data.startTouch;

    data.callback({
      ratio,
      origin: data.origin,
    });
  },

  press(e, data) {
    if (e.touches.length !== data.pointers) {
      return;
    }

    if (e.type === "touchstart") {
      data.startTouch = e;
      data.timeout = setTimeout(() => {
        data.callback();
        data.timeout = "complete";
      }, data.time);
    } else if (e.type === "touchend") {
      clearTimeout(data.timeout);
      e.timeStamp - data.startTouch.timeStamp > data.time &&
        data.timeout !== "complete" &&
        data.callback();
    }
  },

  rotate(e, data) {
    if (e.type === "touchstart") {
      e.preventDefault();
      data.origin = {};

      if (e.touches.length === 1 && data.pointers === 1) {
        const {
          left,
          top,
          width,
          height
        } =
        data.element.getBoundingClientRect();

        data.origin.pivot = {
          x: left + width / 2,
          y: top + height / 2,
        };
      } else if (e.touches.length === 2 && data.pointers === 2) {
        const [t2, t1] = e.touches;
        const position = {
          x1: t1.clientX,
          x2: t2.clientX,
          y1: t1.clientY,
          y2: t2.clientY,
        };

        data.origin.pivot = {
          x: (position.x1 + position.x2) / 2,
          y: (position.y1 + position.y2) / 2,
        };
      } else {
        return;
      }
    } else if (e.type === "touchmove") {
      e.preventDefault();
      let input;

      if (e.touches.length === 1 && data.pointers === 1) {
        input = e.touches[0];
      } else if (e.touches.length === 2 && data.pointers === 2) {
        const getRightMostTouch = (touches) => {
          let rightMost = null;
          const distance = Number.MIN_VALUE;
          touches.forEach((touch) => {
            if (touch.clientX > distance) {
              rightMost = touch;
            }
          });
          return rightMost;
        };

        input = getRightMostTouch(e.touches);
      } else {
        return;
      }

      const getAngle = (x1, y1, x2, y2) => {
        const value = Math.atan2(y2 - y1, x2 - x1);
        const result = value * (180 / Math.PI);
        return Math.round(result + 360) % 360;
      };

      data.origin.currentAngle = getAngle(
        data.origin.pivot.x,
        data.origin.pivot.y,
        input.clientX,
        input.clientY
      );

      if (!data.origin.initialAngle) {
        data.origin.initialAngle = data.origin.previousAngle =
          data.origin.currentAngle;

        data.origin.distance = data.origin.change = 0;
      } else {
        data.origin.change =
          data.origin.currentAngle - data.origin.previousAngle;

        data.origin.distance += data.origin.change;
      }

      data.origin.previousAngle = data.origin.currentAngle;

      const result = {
        currentAngle: data.origin.currentAngle,
        distance: data.origin.distance,
        change: data.origin.change,
      };

      data.callback(result);
    } else if (e.type === "touchend") {
      data.origin = {};
    }
  },

  pan(e, data) {
    if (e.type === "touchstart") {
      data.startTouch = e;
    } else if (e.type === "touchmove" && data.startTouch) {
      e.preventDefault();
      data.touch = e;
      const {
        clientX,
        clientY
      } = data.startTouch.touches[0];
      const {
        clientX: x,
        clientY: y
      } = data.touch.touches[0];

      let movement = {
        x: 0,
        y: 0,
      };

      const moveValue = {
        x: x - clientX,
        y: y - clientY,
      };

      const displacement = {
        x: {
          direction: moveValue.x < 0 ? "left" : "right",
          value: Math.abs(moveValue.x),
        },
        y: {
          direction: moveValue.y < 0 ? "up" : "down",
          value: Math.abs(moveValue.y),
        },
      };

      if (
        data.direction === "all" &&
        (displacement.x.value > data.threshold ||
          displacement.y.value > data.threshold)
      ) {
        movement = {
          x: moveValue.x,
          y: moveValue.y,
        };

        data.callback(movement);
        data.startTouch = data.touch;
      } else {
        const axis =
          data.direction === "left" ||
          data.direction === "right" ||
          data.direction === "x" ?
          "x" :
          "y";

        if (
          displacement[axis].value > data.threshold &&
          (data.direction === axis ||
            displacement[axis].direction === data.direction)
        ) {
          movement[axis] = moveValue[axis];

          data.callback(movement);
          data.startTouch = data.touch;
        }
      }
    }
  },
};

export default {
  mounted(el, binding) {
    if (binding.value === false) return;
    const {
      arg,
      modifiers
    } = binding;

    // fix allowing every mdbTouch instance to have its own data
    el.data = {
      ...el.data,
      [arg]: null,
    };

    el.data[arg] = {
      element: el,
      startTouch: null,
      touch: null,
      timeout: null,
      origin: {
        x: 0,
        y: 0,
      },
      tapCount: 0,
      modifiers,
      angle: binding.value.angle || 0,
      threshold: binding.value.threshold || 20,
      direction: binding.value.direction || "all",
      interval: binding.value.interval || 500,
      timer: null,
      time: binding.value.time || 250,
      taps: binding.value.taps || 1,
      pointers: binding.value.pointers || 1,
      callback: binding.value.callback || binding.value,
    };

    el.addEventListener("touchstart", (e) => handlers[arg](e, el.data[arg]));

    if (arg !== "tap") {
      el.addEventListener("touchmove", (e) => handlers[arg](e, el.data[arg]));

      el.addEventListener("touchend", (e) => handlers[arg](e, el.data[arg]));
    }
  },
  updated(el, binding) {
    if (binding.value === false) return;

    const {
      arg
    } = binding;

    Object.keys(binding.value).forEach((key) => {
      if (binding.value[key] !== binding.oldValue[key]) {
        el.data[arg] = {
          ...el.data[arg],
          [key]: binding.value[key],
        };
      }
    });
  },
  unmounted(el, binding) {
    if (binding.value === false) return;

    const {
      arg
    } = binding;

    el.removeEventListener("touchstart", handlers[arg]);
    el.removeEventListener("touchend", handlers[arg]);
    el.removeEventListener("touchmove", handlers[arg]);
  },
};